import { ParisiPosturalAssessment } from '../dtos/parisi-evaluation-report.dto';

export const ParisiPosturalAssessmentQuestions: ParisiPosturalAssessment[] = [
  {
    id: 1,
    view: 'Anterior',
    checkpoint: 'Foot / Knee',
    observation: 'Foot Turns Out / Knee In-Out',
    exercise: 'Goblet Squat / Band Side Step',
  },
  {
    id: 2,
    view: 'Lateral',
    checkpoint: 'L-P-FF-C',
    observation: 'Excessive L/B Arch-L/B Flex',
    exercise: 'Step up / D. Bug-Prone Ball Cobra',
  },
  {
    id: 3,
    view: 'Lateral',
    checkpoint: 'Upper Body',
    observation: 'Arms Fall Forward',
    exercise: 'S.Leg - 2 Arm Row / Prone Ball Cobra',
  },
  {
    id: 4,
    view: 'Lateral',
    checkpoint: 'Head',
    observation: 'Forward Head',
    exercise: 'Prone Ball Cobra',
  },
  {
    id: 5,
    view: 'Posterior',
    checkpoint: 'Foot',
    observation: 'Foot Flattens',
    exercise: 'Banded Knee Squat',
  },
  {
    id: 6,
    view: 'Posterior',
    checkpoint: 'Foot',
    observation: 'Heel Raise',
    exercise: 'Rock Bridge',
  },
  {
    id: 7,
    view: 'Posterior',
    checkpoint: 'L-P-H-C',
    observation: 'Asymmetrical Weight Shift',
    exercise: 'Banded Knee Squat',
  },
  {
    id: 8,
    view: 'Posterior',
    checkpoint: 'Upper Body',
    observation: 'Shoulder Elevation',
    exercise: 'Alt. Super-Man/Woman',
  },
];
