import { Component, OnInit } from '@angular/core';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserService } from '@webplatform/shared/services/user.service';
import { environment } from '@webplatform-environment/environment';
import { FeatureEnum, Role } from '@ledsreact/data-models';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  readonly environmentName: string = environment.environmentName;

  mainPages: string[] = [AppRoutingEnum.INSIGHTS];
  secondaryPages: string[] = [AppRoutingEnum.PLAYERS_MANAGEMENT];
  settingsPages: string[] = [AppRoutingEnum.SETTINGS, AppRoutingEnum.LOGOUT];

  onHover: boolean = false;

  constructor(private _userService: UserService) {}

  ngOnInit() {
    if (this._userService.isFeatureEnabled(FeatureEnum.PARISI)) {
      this.mainPages.unshift(AppRoutingEnum.PARISI);
    }
    if (this._userService.isFeatureEnabled(FeatureEnum.DASHBOARD)) {
      this.mainPages.unshift(AppRoutingEnum.DASHBOARD);
    }

    if (this._userService.user.isAdmin) {
      this.secondaryPages.push(AppRoutingEnum.CONES);
      this.secondaryPages.push(AppRoutingEnum.CLUBS);
      this.secondaryPages.push(AppRoutingEnum.TRAINERS);
      this.secondaryPages.push(AppRoutingEnum.DEBUG_DATA);
    } else if (this._userService.user.isAdminReadOnly) {
      this.secondaryPages.push(AppRoutingEnum.CONES);
      this.secondaryPages.push(AppRoutingEnum.CLUBS);
      this.secondaryPages.push(AppRoutingEnum.DEBUG_DATA);
    } else if (this._userService.activeProfile.role === Role.MANAGER) {
      this.secondaryPages.push(AppRoutingEnum.TRAINERS);
    } else if (this._userService.activeProfile.role === Role.TESTER) {
      this.secondaryPages.push(AppRoutingEnum.TESTING);
      this.secondaryPages.push(AppRoutingEnum.TESTING_CHARTS);
    }
  }

  pageTrackByFn(index: number, _: string) {
    return index;
  }
}
