export enum ParisiMetric {
  'totaltimereactiontime_s' = 'totaltimereactiontime_s', // Total time WITHOUT reaction time (the default metric)
  'total_time_s' = 'total_time_s', // Total time WITH reaction time
  'zone_5-10_starttime_s' = 'zone_5-10_starttime_s', // Split time @ 5
  'zone_10-15_starttime_s' = 'zone_10-15_starttime_s', // Split time @ 10
  'zone_15-20_starttime_s' = 'zone_15-20_starttime_s', // Split time @ 15
  'zone_20-25_starttime_s' = 'zone_20-25_starttime_s', // Split time @ 20
  'zone_25-30_starttime_s' = 'zone_25-30_starttime_s', // Split time @ 25
  'zone_30-35_starttime_s' = 'zone_30-35_starttime_s', // Split time @ 30
}

export const DefaultParisiMetric = ParisiMetric.totaltimereactiontime_s;
