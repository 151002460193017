export enum FeatureEnum {
  POWER_VELOCITY_PROFILE = 'POWER_VELOCITY_PROFILE',
  ACCELERATION_PHASE_GRAPH = 'ACCELERATION_PHASE_GRAPH',
  EXPORT_GRAPH_DATAPOINTS = 'EXPORT_GRAPH_DATAPOINTS',
  IMPORT_PLAYER_FROM_CSV = 'IMPORT_PLAYER_FROM_CSV',
  LIVE_DATA = 'LIVE_DATA',
  TEST_INITIATED_BY_PLAYER = 'TEST_INITIATED_BY_PLAYER',
  DASHBOARD = 'DASHBOARD',
  MULTIPLAYER = 'MULTIPLAYER',
  PARISI = 'PARISI',
}
