import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingEnum } from './app-routing.enum';
import { AuthenticatedUserAuthGuard } from './shared/guards/authenticated-user.guard';
import { Role } from '@ledsreact/data-models';
import { ActiveProfileGuard } from './shared/guards/active-profile.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: `/${AppRoutingEnum.INSIGHTS}`,
    pathMatch: 'full',
  },
  {
    path: AppRoutingEnum.LOGIN,
    loadChildren: () => import('./unauthenticated/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: AppRoutingEnum.PASSWORD,
    loadChildren: () => import('./unauthenticated/password/password.module').then((m) => m.PasswordModule),
  },
  {
    path: AppRoutingEnum.INVITE,
    loadChildren: () => import('./unauthenticated/invite/invite.module').then((m) => m.InviteModule),
  },
  {
    path: AppRoutingEnum.LOGOUT,
    loadChildren: () => import('./unauthenticated/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: AppRoutingEnum.CHOOSE_CLUB,
    canActivate: [AuthenticatedUserAuthGuard],
    loadChildren: () => import('./authenticated/choose-club/choose-club.module').then((m) => m.ChooseClubModule),
  },
  {
    path: AppRoutingEnum.DASHBOARD,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    loadChildren: () => import('./authenticated/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: AppRoutingEnum.PARISI,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    loadChildren: () => import('./authenticated/parisi/parisi.module').then((m) => m.ParisiModule),
  },
  {
    path: AppRoutingEnum.INSIGHTS,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    loadChildren: () => import('./authenticated/insights/insights.module').then((m) => m.InsightsModule),
  },
  {
    path: AppRoutingEnum.PLAYERS_MANAGEMENT,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    loadChildren: () => import('./authenticated/players/players.module').then((m) => m.PlayersModule),
  },
  {
    path: AppRoutingEnum.CONES,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    data: { roles: [Role.ADMIN, Role.ADMIN_READ_ONLY] },
    loadChildren: () => import('./authenticated/cones/cones.module').then((m) => m.ConesModule),
  },
  {
    path: AppRoutingEnum.CLUBS,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    data: { roles: [Role.ADMIN, Role.ADMIN_READ_ONLY] },
    loadChildren: () => import('./authenticated/clubs/clubs.module').then((m) => m.ClubsModule),
  },
  {
    path: AppRoutingEnum.DEBUG_DATA,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    data: { roles: [Role.ADMIN, Role.ADMIN_READ_ONLY] },
    loadChildren: () => import('./authenticated/debug-data/debug-data.module').then((m) => m.DebugDataModule),
  },
  {
    path: AppRoutingEnum.TRAINERS,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    data: { roles: [Role.ADMIN, Role.MANAGER] },
    loadChildren: () => import('./authenticated/trainers/trainers.module').then((m) => m.TrainersModule),
  },
  {
    path: AppRoutingEnum.SETTINGS,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    loadChildren: () => import('./authenticated/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: AppRoutingEnum.TESTING,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    data: { roles: [Role.ADMIN, Role.TESTER] },
    loadChildren: () => import('./authenticated/testing/testing.module').then((m) => m.TestingModule),
  },
  {
    path: AppRoutingEnum.TESTING_CHARTS,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    data: { roles: [Role.ADMIN, Role.TESTER] },
    loadChildren: () =>
      import('./authenticated/testing-charts/testing-charts.module').then((m) => m.TestingChartsModule),
  },
  {
    path: AppRoutingEnum.ADMIN,
    canActivate: [AuthenticatedUserAuthGuard, ActiveProfileGuard],
    data: { roles: [Role.ADMIN] },
    loadChildren: () => import('./authenticated/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '**',
    redirectTo: AppRoutingEnum.INSIGHTS,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'ignore',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
