/**
 * These are all the Parisi tests that are available in the system.
 */
export enum ParisiTest {
  '10y' = '10y', // 10-Yard Sprint
  '20y' = '20y', // 20-Yard Sprint
  '40y' = '40y', // 40-Yard Sprint
  'ss' = 'ss', // 5-10-5
  'hop' = 'hop', // 5-HOP
  'vj' = 'vj', // vertical jump
  '200y' = '200y', // 4 x 25-Yard COD
  'cu' = 'cu', // chin ups
}

/**
 * This maps the Parisi test name to the default public exercise name.
 */
export const ParisiTestMapping: Record<ParisiTest, string[]> = {
  '10y': ['Sprint_10Y', 'Sprint_10M'],
  '20y': ['Sprint_20Y', 'Sprint_20M'],
  '40y': ['Sprint_40Y', 'Sprint_40M'],
  ss: ['5-10-5_Random', '5-10-5_Right', '5-10-5_Left', '5-10-5_test_customizable'],

  // We may be able to pre-fill this test once the "Bronco" test type is implemented
  '200y': [],

  // We cannot pre-fill these tests
  hop: [],
  vj: [],
  cu: [],
};

export const ParisiTestHigherIsBetter = [ParisiTest['hop'], ParisiTest['cu'], ParisiTest['cu']];
