import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ParisiReportQuery,
  ParisiEvaluationReport,
  ParisiPlayer,
  ParisiPlayerSummary,
  ParisiSubmittedReport,
  Paginated,
  ParisiClubCoach,
  ParisiClub,
  ParisiTest,
  ParisiPreviousAttempt,
  ParisiLastAndBestAttemptsByTest,
  ParisiTotalScore,
  ParisiPerformanceMetric,
  ParisiGetTotalScore,
} from '@ledsreact/data-models';

@Injectable()
export class ParisiHttpService {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {}

  getPlayerSummaries(query: ParisiReportQuery): Observable<Paginated<ParisiPlayerSummary>> {
    const params: ParisiReportQuery = {
      clubId: query.clubId,
      coachId: query.coachId,
      playerIds: query.playerIds,
      dateStart: query.dateStart,
      dateEnd: query.dateEnd,
      page: query.page,
      limit: query.limit,
      searchTerm: query.searchTerm,
      orderBy: query.orderBy,
      orderDirection: query.orderDirection,
      includeAllResults: query.includeAllResults,
    };
    return this.http.get<Paginated<ParisiPlayerSummary>>(`${this.baseUrl}/parisi/summaries`, {
      params: {
        ...Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined && value !== null)),
      },
    });
  }

  getPlayers({
    page,
    limit,
    searchTerm,
    clubId,
    coachId,
    playerId,
  }: {
    page?: number;
    limit?: number;
    searchTerm?: string;
    clubId?: number;
    coachId?: number;
    playerId?: number;
  }): Observable<Paginated<ParisiPlayer>> {
    const params = { page, limit, searchTerm, clubId, coachId, playerId };
    return this.http.get<Paginated<ParisiPlayer>>(`${this.baseUrl}/parisi/players`, {
      params: Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined && value !== null)),
    });
  }

  submitReport(report: ParisiSubmittedReport): Observable<ParisiEvaluationReport> {
    return this.http.post<ParisiEvaluationReport>(`${this.baseUrl}/parisi/reports`, report);
  }

  getReport(id: string): Observable<ParisiEvaluationReport> {
    return this.http.get<ParisiEvaluationReport>(`${this.baseUrl}/parisi/reports/${id}`);
  }

  getParisiClubs(): Observable<ParisiClub[]> {
    return this.http.get<ParisiClub[]>(`${this.baseUrl}/parisi/clubs`);
  }

  isParisiClub(clubId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/parisi/clubs/${clubId}/is-parisi`);
  }

  getClubCoaches(clubId: number): Observable<ParisiClubCoach[]> {
    return this.http.get<ParisiClubCoach[]>(`${this.baseUrl}/parisi/clubs/${clubId}/coaches`);
  }

  getScore(clubId: number, playerId: number, test: ParisiTest, value: number): Observable<number> {
    const params = { clubId, playerId, test, value };
    return this.http.get<number>(`${this.baseUrl}/parisi/score`, {
      params: Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined && value !== null)),
    });
  }

  getTotalScore(playerId: number, metrics: ParisiPerformanceMetric[]): Observable<ParisiTotalScore> {
    return this.http.post<ParisiTotalScore>(`${this.baseUrl}/parisi/score/total`, {
      playerId,
      metrics,
    } as ParisiGetTotalScore);
  }

  getLastAndBestAttempts(clubId: number, playerId: number): Observable<ParisiLastAndBestAttemptsByTest> {
    const params = { clubId, playerId };
    return this.http.get<ParisiLastAndBestAttemptsByTest>(`${this.baseUrl}/parisi/attempts`, {
      params: Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined && value !== null)),
    });
  }

  getLastAttemptsForTest(
    clubId: number,
    playerId: number,
    test: ParisiTest,
    limit?: number
  ): Observable<ParisiPreviousAttempt[]> {
    const params = { clubId, playerId, test, limit };
    return this.http.get<ParisiPreviousAttempt[]>(`${this.baseUrl}/parisi/test/attempts`, {
      params: Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined && value !== null)),
    });
  }

  deleteReport(reportId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/parisi/reports/${reportId}`);
  }
}
